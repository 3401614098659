/* main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 680px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}

h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 16px;
}
a {
  border-bottom: 1px solid white;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #0076ff;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '\`';
} */

.title {
  color: #f4f4f4 !important;
  font-size: 25px;
}

.layout .github {
  float: right;
  width: 240px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout-content {
  /* min-height: 280px; */
  padding: 24px;
  /* background: #fff; */
}
.layout .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}


.column-left {
  width: 50%;
  float: left;
  box-sizing: border-box;
}

.column-right {
  width: 50%;
  float: left;
  box-sizing: border-box;
}

.col-container {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.col {
  flex: 1;
  padding: 16px;
}

@media only screen and (max-width: 800px) {
  .col-container {
    flex-direction: column;
  }

  .ant-layout-content {
    padding: 0px !important;
  }

  .site-layout-content {
    padding: 0px !important;
  }

  .format {
    width: unset !important;
  }
}

.ant-layout-content {
  padding: 0px 50px;
}

.format {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
  margin: 10px;
  width: 40vw;
  /* user-select: contain; */
}

.force-select {  
  -webkit-user-select: all;  /* Chrome 49+ */
  -moz-user-select: all;     /* Firefox 43+ */
  -ms-user-select: all;      /* No support yet */
  user-select: all;          /* Likely future */   
}
